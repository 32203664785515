/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-image.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./view-image.component";
var styles_VsViewImageComponent = [i0.styles];
var RenderType_VsViewImageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_VsViewImageComponent, data: {} });
export { RenderType_VsViewImageComponent as RenderType_VsViewImageComponent };
export function View_VsViewImageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { fileControl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "width", 0], [8, "height", 0], [8, "className", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImageUrl(); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.width, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.height, ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.class, ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_VsViewImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vs-view-image", [], null, null, null, View_VsViewImageComponent_0, RenderType_VsViewImageComponent)), i1.ɵdid(1, 245760, null, 0, i2.VsViewImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VsViewImageComponentNgFactory = i1.ɵccf("vs-view-image", i2.VsViewImageComponent, View_VsViewImageComponent_Host_0, { file: "file", width: "width", height: "height", class: "class", size: "size", defaultNoImageUrl: "defaultNoImageUrl" }, {}, []);
export { VsViewImageComponentNgFactory as VsViewImageComponentNgFactory };
