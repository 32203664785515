/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./thumbnail.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng/components/fileupload/fileupload.ngfactory";
import * as i3 from "primeng/components/fileupload/fileupload";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common/http";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "./thumbnail.component";
import * as i9 from "../../../services/module-config.service";
import * as i10 from "../services/file-upload.service";
var styles_VsThumbnailComponent = [i0.styles];
var RenderType_VsThumbnailComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_VsThumbnailComponent, data: {} });
export { RenderType_VsThumbnailComponent as RenderType_VsThumbnailComponent };
function View_VsThumbnailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vui l\u00F2ng c\u1EA5u h\u00ECnh \u0111\u1ECBa ch\u1EC9 file upload t\u1EEB module"]))], null, null); }
function View_VsThumbnailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "vs-btn-thumbnail text-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectThumbnailClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chooseLabel; _ck(_v, 2, 0, currVal_0); }); }
function View_VsThumbnailComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "display:none;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p-fileUpload", [["accept", "image/*"], ["auto", "true"], ["maxFileSize", "1000000"], ["mode", "basic"], ["name", "files"]], null, [[null, "onBeforeUpload"], [null, "onUpload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onBeforeUpload" === en)) {
        var pd_0 = (_co.onBeforeUpload($event) !== false);
        ad = (pd_0 && ad);
    } if (("onUpload" === en)) {
        var pd_1 = (_co.onUploadEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FileUpload_0, i2.RenderType_FileUpload)), i1.ɵdid(2, 5423104, [[1, 4], ["fileControl", 4]], 1, i3.FileUpload, [i1.ElementRef, i4.DomSanitizer, i1.NgZone, i5.HttpClient], { name: [0, "name"], url: [1, "url"], multiple: [2, "multiple"], accept: [3, "accept"], auto: [4, "auto"], maxFileSize: [5, "maxFileSize"], chooseLabel: [6, "chooseLabel"], mode: [7, "mode"] }, { onBeforeUpload: "onBeforeUpload", onUpload: "onUpload" }), i1.ɵqud(603979776, 2, { templates: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "files"; var currVal_1 = i1.ɵinlineInterpolate(1, "", (_co.apiUrl + "/Image"), ""); var currVal_2 = false; var currVal_3 = "image/*"; var currVal_4 = "true"; var currVal_5 = "1000000"; var currVal_6 = _co.chooseLabel; var currVal_7 = "basic"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_VsThumbnailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "vs-thumbnail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["height", "150"], ["width", "150"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsThumbnailComponent_4)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsThumbnailComponent_5)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.disabled; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.disabled; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.defaultNoImageUrl, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_VsThumbnailComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "vs-btn-thumbnail text-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "text text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["X\u00F3a"]))], null, null); }
function View_VsThumbnailComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "vs-thumbnail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["height", "150"], ["width", "150"]], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError($event, _co) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsThumbnailComponent_7)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.disabled; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImageMedium(_co.fileUploaded); _ck(_v, 2, 0, currVal_0); }); }
function View_VsThumbnailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsThumbnailComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsThumbnailComponent_6)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fileUploaded == ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.fileUploaded != ""); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_VsThumbnailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileControl: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsThumbnailComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsThumbnailComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.apiUrl == ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.apiUrl != ""); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_VsThumbnailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "vs-thumbnail", [], null, null, null, View_VsThumbnailComponent_0, RenderType_VsThumbnailComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.VsThumbnailComponent]), i1.ɵdid(2, 245760, null, 0, i8.VsThumbnailComponent, [i9.ModuleConfigService, i10.VsFileUploadService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var VsThumbnailComponentNgFactory = i1.ɵccf("vs-thumbnail", i8.VsThumbnailComponent, View_VsThumbnailComponent_Host_0, { chooseLabel: "chooseLabel", defaultNoImageUrl: "defaultNoImageUrl" }, { onUploaded: "onUploaded", onRemoved: "onRemoved" }, []);
export { VsThumbnailComponentNgFactory as VsThumbnailComponentNgFactory };
