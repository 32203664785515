import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    try {
        // document.write(`<script type="text/javascript" src="${documentServer}/web-apps/apps/api/documents/api.js"></script>`);
    }
    catch (e) {
        console.error('cannot load document server');
    }
    enableProdMode();
}
const bootstrap = () => __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
const ɵ0 = bootstrap;
if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('Ammm.. HMR is not enabled for webpack');
    }
}
else {
    bootstrap().catch(err => console.log(err));
}
export { ɵ0 };
