/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-notification.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../file-upload/view-image/view-image.component.ngfactory";
import * as i3 from "../file-upload/view-image/view-image.component";
import * as i4 from "../../pipes/summary.pipe";
import * as i5 from "@angular/common";
import * as i6 from "ngx-infinite-scroll";
import * as i7 from "../../services/signalr.service";
import * as i8 from "../../services/module-config.service";
import * as i9 from "../../services/topicusers.service";
import * as i10 from "../../auth/authen.service";
import * as i11 from "./app-notification.component";
import * as i12 from "../../services/user.service";
import * as i13 from "../../services/notification.service";
import * as i14 from "../../../services/eventemitter.service";
import * as i15 from "@angular/router";
var styles_VsAppNotificationComponent = [i0.styles];
var RenderType_VsAppNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VsAppNotificationComponent, data: {} });
export { RenderType_VsAppNotificationComponent as RenderType_VsAppNotificationComponent };
function View_VsAppNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "topbar-badge animated rubberBand"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalUnRead; _ck(_v, 1, 0, currVal_0); }); }
function View_VsAppNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "li", [["role", "menuitem"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "a", [["class", "topbar-message"], ["href", "javascript:"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "noti-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "vs-view-image", [], null, [[null, "fileChange"]], function (_v, en, $event) { var ad = true; if (("fileChange" === en)) {
        var pd_0 = ((_v.context.$implicit.message.image = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VsViewImageComponent_0, i2.RenderType_VsViewImageComponent)), i1.ɵdid(4, 245760, null, 0, i3.VsViewImageComponent, [], { file: [0, "file"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "noti-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "noti-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "noti-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "noti-time"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-clock-o"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵppd(14, 2)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.message.image; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "noti-item ", _co.getClassRead(_v.context.$implicit), ""); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.$implicit.message.title; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.message.content, 18)); _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent, 1), _v.context.$implicit.message.time, "HH:mm dd/MM/yyyy")); _ck(_v, 13, 0, currVal_4); }); }
export function View_VsAppNotificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SummaryPipe, []), i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(402653184, 1, { notificationDetail: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "a", [["href", "#"], ["style", "margin-top: 5px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMenuEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "topbar-icon animated swing fa fa-fw fa-bell-o"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsAppNotificationComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "topbar-item-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Messages"])), (_l()(), i1.ɵeld(9, 0, null, null, 10, "ul", [["class", "layout-menu fadeInDown custom-notification"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [["class", "noti-header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.headerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Th\u00F4ng b\u00E1o"])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["infinite-scroll", ""], ["style", "width:100%;height:350px;overflow:auto"]], null, [[null, "scrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolled" === en)) {
        var pd_0 = (_co.onScrollDown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 4866048, null, 0, i6.InfiniteScrollDirective, [i1.ElementRef, i1.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], infiniteScrollThrottle: [1, "infiniteScrollThrottle"], scrollWindow: [2, "scrollWindow"] }, { scrolled: "scrolled" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VsAppNotificationComponent_2)), i1.ɵdid(16, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "li", [["class", "noti-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "a", [["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.readAll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0110\u00E1nh d\u1EA5u t\u1EA5t c\u1EA3 l\u00E0 \u0111\u00E3 \u0111\u1ECDc"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.totalUnRead > 0); _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.infiniteScrollDistance; var currVal_2 = _co.infiniteScrollThrottle; var currVal_3 = false; _ck(_v, 14, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.notifications; _ck(_v, 16, 0, currVal_4); }, null); }
export function View_VsAppNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["vs-app-notification", ""]], null, null, null, View_VsAppNotificationComponent_0, RenderType_VsAppNotificationComponent)), i1.ɵprd(512, null, i7.SignalRService, i7.SignalRService, [i8.ModuleConfigService, i9.TopicUsersService, i10.VsAuthenService]), i1.ɵdid(2, 114688, null, 0, i11.VsAppNotificationComponent, [i7.SignalRService, i12.UserService, i13.NotificationService, i14.EventEmitterService, i15.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var VsAppNotificationComponentNgFactory = i1.ɵccf("li[vs-app-notification]", i11.VsAppNotificationComponent, View_VsAppNotificationComponent_Host_0, {}, { openMenu: "openMenu" }, []);
export { VsAppNotificationComponentNgFactory as VsAppNotificationComponentNgFactory };
