import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { VsFsFileItem } from '../models/file-item';
import { VsFsFileUploadService, FileViewerType } from '../services/file-upload.service';
import { ControlValueAccessor } from '@angular/forms';
import { NgbModal } from '../../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { UserService } from '../../../services/user.service';
export class VsFsListFileComponent {
    constructor(_fileService, ngbModal, _userService) {
        this._fileService = _fileService;
        this.ngbModal = ngbModal;
        this._userService = _userService;
        this.files = [];
        this.disabled = false;
        this.ids = [];
        this.linkView = '';
        this.selectedFile = new VsFsFileItem();
        this.currentIds = '';
        this.isMngFile = false;
        this.viewOnly = false;
        this.isSingleFile = false;
        this.onUploaded = new EventEmitter();
        this.onRemoved = new EventEmitter();
        this.onClick = new EventEmitter();
        this.onSelectItem = new EventEmitter();
        this.onChangeControl = (obj) => { };
        this.onTouched = () => { };
    }
    ngOnInit() {
        this.userId = this._userService.getBasicUserInfo().userId;
    }
    writeValue(obj) {
        if (obj) {
            this.files = obj;
        }
    }
    registerOnChange(fn) {
        this.onChangeControl = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    downloadFile(item) {
        window.open(this._fileService.getLinkDownload(item.id));
    }
    openFile(item) {
    }
    deleteFile(item) {
        if (item.typeId === 2) {
            this._fileService.deleteFolder(this.userId, item.id);
            const fileToRemove = this.files.findIndex(x => x.fileName === item.fileName);
            this.files.splice(fileToRemove, 1);
        }
        else {
            this._fileService.deleteFile(item);
            const fileToRemove = this.files.findIndex(x => x.fileName === item.fileName);
            this.files.splice(fileToRemove, 1);
            this.onChangeControl(this.files);
            this.onRemoved.next(item);
        }
    }
    deleteFileTemp(item) {
        if (item.typeId === 2) {
            const fileToRemove = this.files.findIndex(x => x.fileName === item.fileName);
            this.files.splice(fileToRemove, 1);
        }
        else {
            const fileToRemove = this.files.findIndex(x => x.fileName === item.fileName);
            this.files.splice(fileToRemove, 1);
            this.onChangeControl(this.files);
            this.onRemoved.next(item);
        }
    }
    viewFile(item) {
        window.open(this._fileService.getLinkView(item.id), '_blank');
    }
    onItemClick(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selectedFile = new VsFsFileItem();
            if (item.typeId === 2) {
                this.onChangeControl(this.files);
                this.onClick.next(item);
            }
            else {
                const fileViewerType = yield this._fileService.getFileViewerType(item.fileExt);
                if (fileViewerType == FileViewerType.OfficeFile || fileViewerType == FileViewerType.Image) {
                    console.log(item);
                    this.selectedFile = item;
                    this.linkView = yield this._fileService.getLinkDownload(item.id);
                    this._FileViewerComponent.showPopup(this.selectedFile.rawFileName, this.linkView);
                    // this.modalReference = this.ngbModal.open(this.fileViewer, {
                    //     keyboard: false,
                    //     size: 'lg',
                    //     windowClass: 'document-viewer'
                    // });
                }
                else {
                    window.open(this._fileService.getLinkDownload(item.id));
                }
            }
        });
    }
    checkItem(item) {
        if (this.isSingleFile) {
            this.files.forEach(element => {
                if (element.id !== item.id) {
                    element.checked = false;
                }
            });
            if (item.checked) {
                this.ids = [];
                this.ids.push(item.id);
                item.checked = true;
            }
            else {
                this.ids.splice(this.ids.indexOf(item.id), 1);
                item.checked = false;
            }
            this.onChangeControl(this.ids);
            this.onSelectItem.next(this.ids + '');
        }
        else {
            this.ids = this.currentIds.split(',').filter(s => s !== '');
            if (item.checked) {
                // Kiểm tra nếu Id chưa có trong currentIds thì bổ sung
                if (this.currentIds.indexOf(item.id + ',') < 0 && this.currentIds.indexOf(',' + item.id) < 0) {
                    this.ids.push(item.id);
                    item.checked = true;
                    this.onSelectItem.next(this.ids + ',');
                }
            }
            else {
                // Kiểm tra nếu Id có trong currentIds thì remove để xóa
                this.currentIds = this.currentIds.replace(item.id + ',', '');
                this.currentIds = this.currentIds.replace(',' + item.id, '');
                this.ids.splice(this.ids.indexOf(item.id), 1);
                item.checked = false;
                this.onSelectItem.next(this.currentIds);
            }
            this.onChangeControl(this.ids);
        }
        // alert(this.ids + this.currentIds);
    }
    onClose() {
        this.modalReference.close();
    }
}
